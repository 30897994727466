import $ from 'jquery';
import 'bootstrap/js/dist/modal';
import progressBar from '@animalequality/progress-bar'

$(document).ready(() => {
    const form$ = $('#form');
    const button$ = form$.find('button[type=submit]');

    button$.prop('disabled', false);

    form$.submit((event) => {
        const spinner$ = button$.find('.spinner-border');
        const errorContainer$ = form$.find('.form-error');

        event.preventDefault();
        errorContainer$.addClass('d-none');

        if ($('#newsletter').get(0).checked) {
            button$.prop('disabled', true);
            spinner$.removeClass('d-none');

            $.ajax({
                url: 'https://listo.animalequality.org/api/subscribe/dyfmx',
                type: 'POST',
                contentType: "application/json; charset=utf-8",
                dataType: 'json',
                headers: {
                    'Accept': 'application/json'
                },
                data: JSON.stringify({
                    first_name: $('#firstname').val(),
                    last_name: $('#lastname').val(),
                    email: $('#email').val()
                })
            }).then(response => {
                if (response && response.status === 'ok') {
                    $('#modal-thank-you').modal();
                    form$.get(0).reset();
                    setTimeout(function() { $('#pdf-link').get(0).click(); }, 1000);
                    if (typeof window.dataLayer === 'object') {
                        window.dataLayer.push({
                            event: 'newsletter:success',
                            eventAction: 'subscribe'
                        });
                    }
                } else {
                    errorContainer$.html(response.errors[0]);
                    errorContainer$.removeClass('d-none');
                }
            }, (error) => {
                errorContainer$.html(error.responseJSON ? error.responseJSON.errors[0] : 'Ocurrió un error');
                errorContainer$.removeClass('d-none');
            }).always(() => {
                button$.prop('disabled', false);
                spinner$.addClass('d-none');
            });
        }
    });

    progressBar(
        $('#progress-bar').get(0),
        'https://listo.animalequality.org/api/count/dyfmx',
        'total_items',
        'es-MX',
        0.1,
        'personas ya se suscribieron',
        null,
        0,
        [500000]
    );
});
